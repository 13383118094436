<script setup>
import { ref, onMounted } from 'vue';

const componentKey = ref(0);

onMounted(() => {
    if (!localStorage.getItem('reloaded')) {
        componentKey.value += 1;
        localStorage.setItem('reloaded', 'true');
        location.reload();
    } else {
        localStorage.removeItem('reloaded');
    }
});
</script>
<template>
    <div class="hk-wrapper" data-layout="horizontal" data-layout-style="default" data-menu="light" data-footer="simple">
        <!-- Top Navbar -->
        <Navbar :key="componentKey" :component-key="componentKey" />
        <!-- /Top Navbar -->

        <!-- Main Content -->
        <div class="hk-pg-wrapper">
            <div class="container-xxl">
                <slot />
            </div>
        </div>
        <!-- Footer -->
        <Footer />
        <!-- Footer -->
    </div>
</template>
<style></style>